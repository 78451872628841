import actionCreator from "utils/actionCreator";

/**
 * Action Types
 */
export const barbersActionTypes = {
    READ: "@app/barbers/READ",
    UPDATE: "@app/barbers/UPDATE",
};

/**
 * Redux Actions
 */
export const barbersActions = {
    read: actionCreator(barbersActionTypes.READ),
    update: actionCreator(barbersActionTypes.UPDATE),
};

/**
 * Init State
 */
export const barbersState = {
    barbers: [],
};

/**
 * Default Reducer
 *
 * @param state
 * @param action
 */
const barbersReducer = (state = barbersState, action) => {
    switch (action.type) {
        case barbersActionTypes.READ:
            console.log("This is running: ", action.payload);
            return { ...state, barbers: action.payload };

        case barbersActionTypes.UPDATE:
            const barbers = state.barbers.map(barber => {
                if (barber.id === action.payload.id) return action.payload;
                return barber;
            });
            return { ...state, barbers };

        default:
            return state;
    }
};
export default barbersReducer;
