import React from "react";

const Button = ({
    type = "squared",
    onClick,
    children,
    btnType = "button",
    disabled = false,
    className,
}) => {
    return (
        <button
            type={btnType}
            onClick={onClick}
            className={`btn btn--${type} ${className}`}
            disabled={disabled}
        >
            {children}
        </button>
    );
};

export default Button;
