import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import BlockedDays from "components/molecules/blockedDays/BlockedDays";

import { barbersUpdateActions, barbersUpdateActionTypes } from "sagas/barbers/barbersUpdate";

import Button from "components/atoms/button/Button";
import Error from "components/atoms/error/Error";
import Sidebar from "components/templates/sidebar/Sidebar";
import FormGroup from "components/molecules/formGroup/FormGroup";

import dispatchWithPromise from "utils/dispatchWithPromise";
import useErrorLoader from "hooks/useErrorSelector";
import useLoaderSelector from "hooks/useLoaderSelector";

const BarberSidebar = ({ barber, onClose }) => {
    const [isError] = useErrorLoader(barbersUpdateActionTypes.FAILURE);
    const [isLoading] = useLoaderSelector(barbersUpdateActionTypes.REQUEST);

    const dispatch = useDispatch();

    const { register, handleSubmit, errors } = useForm({
        mode: "onTouched",
        defaultValues: {
            name: barber.name,
            rank: barber.rank,
            rating: barber.rating,
        },
    });

    const onSubmit = values => {
        dispatchWithPromise(dispatch, barbersUpdateActions.request, {
            barberId: barber.id,
            ...values,
        }).then(() => onClose());
    };

    return (
        <Sidebar title="Detajet e berberit" onClose={onClose}>
            <div className="scroll">
                <div className="barber-sidebar">
                    <div className="barber-sidebar__group">
                        <h5>Fotoja e berberit</h5>
                        <div>
                            <figure className="barber-sidebar__figure">
                                <img src={barber.imgUrl} alt="Barber" />
                            </figure>
                        </div>
                    </div>

                    <div className="barber-sidebar__group">
                        <h5>Info e berberit</h5>
                        <form className="barber-sidebar__form">
                            <FormGroup
                                name="name"
                                type="text"
                                label="Emri"
                                ref={register({ required: "Emri eshte i nevojshem" })}
                                errors={errors}
                            />
                            <FormGroup
                                name="rank"
                                type="text"
                                label="Roli"
                                ref={register({ required: "Roli eshte i nevojshem" })}
                                errors={errors}
                            />
                            <FormGroup
                                name="rating"
                                type="text"
                                label="Vlersimi"
                                ref={register({ required: "Vleresimi eshte i nevojshem" })}
                                errors={errors}
                            />
                            {/* Add input array for services */}
                            <FormGroup
                                name="services"
                                type="services"
                                label="Serviset"
                                services={barber.services}
                                ref={register}
                                errors={errors}
                            />

                            <Button btnType="submit" onClick={handleSubmit(onSubmit)}>
                                {isLoading ? "Loading..." : "Submit"}
                            </Button>

                            {isError && (
                                <Error
                                    size="medium"
                                    message="Berberi nuk ka mundur te ndryshohet"
                                />
                            )}
                        </form>
                    </div>

                    <div className="barber-sidebar__group">
                        <h5>Ditët e bllokuara (te ardhshme)</h5>
                        <BlockedDays barber={barber} />
                    </div>
                </div>
            </div>
        </Sidebar>
    );
};

export default BarberSidebar;
