import { takeLatest, put } from "redux-saga/effects";
import axios from "utils/axios";
import actionCreator from "utils/actionCreator";
import { barbersActions } from "./barbers";

/**
 * Action Types
 */
export const barbersBlockedDaysActionTypes = {
    BLOCK_DAY_REQUEST: "@app/barbers/BLOCK_DAY/REQUEST",
    BLOCK_DAY_SUCCESS: "@app/barbers/BLOCK_DAY/SUCCESS",
    BLOCK_DAY_FAILURE: "@app/barbers/BLOCK_DAY/FAILURE",
    UNBLOCK_DAY_REQUEST: "@app/barbers/UNBLOCK_DAY/REQUEST",
    UNBLOCK_DAY_SUCCESS: "@app/barbers/UNBLOCK_DAY/SUCCESS",
    UNBLOCK_DAY_FAILURE: "@app/barbers/UNBLOCK_DAY/FAILURE",
};

/**
 * Redux Actions
 */
export const barbersBlockedDaysActions = {
    blockDayRequest: actionCreator(barbersBlockedDaysActionTypes.BLOCK_DAY_REQUEST),
    blockDaySuccess: actionCreator(barbersBlockedDaysActionTypes.BLOCK_DAY_SUCCESS),
    blockDayFailure: actionCreator(barbersBlockedDaysActionTypes.BLOCK_DAY_FAILURE),
    unblockDayRequest: actionCreator(barbersBlockedDaysActionTypes.UNBLOCK_DAY_REQUEST),
    unblockDaySuccess: actionCreator(barbersBlockedDaysActionTypes.UNBLOCK_DAY_SUCCESS),
    unblockDayFailure: actionCreator(barbersBlockedDaysActionTypes.UNBLOCK_DAY_FAILURE),
};

/**
 * Saga Functions
 */
export const sagas = {
    *blockDay({ payload }) {
        try {
            const { barberId, date } = payload;
            yield axios.post(`/barbers/${barberId}/blocked-days`, { date });

            // Refresh barber data to get updated blocked days
            const { data } = yield axios.get("/barbers");
            yield put(barbersActions.read(data));

            yield put(barbersBlockedDaysActions.blockDaySuccess());
        } catch (error) {
            yield put(barbersBlockedDaysActions.blockDayFailure(error.response?.data.errors));
        }
    },

    *unblockDay({ payload }) {
        try {
            const { barberId, date } = payload;
            yield axios.delete(`/barbers/${barberId}/blocked-days/${date}`);

            // Refresh barber data to get updated blocked days
            const { data } = yield axios.get("/barbers");
            yield put(barbersActions.read(data));

            yield put(barbersBlockedDaysActions.unblockDaySuccess());
        } catch (error) {
            yield put(barbersBlockedDaysActions.unblockDayFailure(error.response?.data.errors));
        }
    },
};

/**
 * Watchers
 */
export function* barbersBlockedDaysWatchers() {
    yield takeLatest(barbersBlockedDaysActionTypes.BLOCK_DAY_REQUEST, sagas.blockDay);
    yield takeLatest(barbersBlockedDaysActionTypes.UNBLOCK_DAY_REQUEST, sagas.unblockDay);
}
