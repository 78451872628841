import React, { useState } from "react";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";

import {
    barbersBlockedDaysActions,
    barbersBlockedDaysActionTypes,
} from "sagas/barbers/barbersBlockedDays";
import Button from "components/atoms/button/Button";
import Error from "components/atoms/error/Error";
import useErrorLoader from "hooks/useErrorSelector";
import useLoaderSelector from "hooks/useLoaderSelector";

const BlockedDays = ({ barber }) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const dispatch = useDispatch();

    // Error and loading states
    const [isBlockError, blockError] = useErrorLoader(
        barbersBlockedDaysActionTypes.BLOCK_DAY_FAILURE,
    );
    const [isUnblockError, unblockError] = useErrorLoader(
        barbersBlockedDaysActionTypes.UNBLOCK_DAY_FAILURE,
    );
    const [isBlocking] = useLoaderSelector(barbersBlockedDaysActionTypes.BLOCK_DAY_REQUEST);
    const [isUnblocking] = useLoaderSelector(barbersBlockedDaysActionTypes.UNBLOCK_DAY_REQUEST);

    const handleBlockDay = () => {
        if (selectedDate) {
            // Format date as YYYY-MM-DD in Europe/Belgrade timezone
            const date = moment(selectedDate).tz("Europe/Belgrade").format("YYYY-MM-DD");

            dispatch(
                barbersBlockedDaysActions.blockDayRequest({
                    barberId: barber.id,
                    date,
                }),
            );
            setSelectedDate(null);
        }
    };

    const handleUnblockDay = date => {
        dispatch(
            barbersBlockedDaysActions.unblockDayRequest({
                barberId: barber.id,
                date,
            }),
        );
    };

    // Format and convert blocked days for the datepicker
    const blockedDays =
        barber.blockedDays?.map(bd => ({
            ...bd,
            // Convert UTC date to Belgrade timezone and format
            formattedDate: moment(bd.date).tz("Europe/Belgrade").format("YYYY-MM-DD"),
        })) || [];

    return (
        <div className="blocked-days">
            <div className="blocked-days__picker">
                <DatePicker
                    selected={selectedDate}
                    onChange={date => setSelectedDate(date)}
                    minDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                    excludeDates={blockedDays.map(bd => new Date(bd.formattedDate))}
                    placeholderText="Zgjedh një datë"
                    inline
                />
                <Button
                    className="blocked-days__block-button"
                    onClick={handleBlockDay}
                    disabled={!selectedDate || isBlocking}
                    type="squared"
                    loading={isBlocking}
                >
                    Blloko ditën
                </Button>
            </div>

            <div className="blocked-days__error-wrapper">
                {isBlockError && <Error size="small" message={blockError?.[0]?.message} />}
            </div>

            {blockedDays.length > 0 && (
                <div className="blocked-days__list">
                    {blockedDays.map(blockedDay => (
                        <div key={blockedDay.date} className="blocked-days__item">
                            <span>{blockedDay.formattedDate}</span>
                            <Button
                                onClick={() => handleUnblockDay(blockedDay.formattedDate)}
                                type="rounded"
                                size="small"
                                loading={isUnblocking}
                                disabled={isUnblocking}
                                className="blocked-days__unblock-button"
                            >
                                Zhblloko
                            </Button>
                        </div>
                    ))}
                </div>
            )}

            {isUnblockError && <Error size="small" message={unblockError?.[0]?.message} />}
        </div>
    );
};

export default BlockedDays;
