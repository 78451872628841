import { all } from "redux-saga/effects";

// Watchers
import { authWatchers } from "sagas/auth/auth";
import { authInitWatchers } from "sagas/auth/authInit";
import { authLogoutWatchers } from "sagas/auth/authLogout";
import { bookingsReadWatchers } from "sagas/bookings/bookingsRead";
import { bookingsDeleteWatchers } from "sagas/bookings/bookingsDelete";
import { bookingsUpdateWatchers } from "sagas/bookings/bookingsUpdate";
import { bookingsAppointmentsWatchers } from "sagas/bookings/bookingsAppointments";
import { bookingsCreateWatchers } from "sagas/bookings/bookingsCreate";
import { productsReadWatchers } from "sagas/products/productsRead";
import { productsUpdateWatchers } from "sagas/products/productsUpdate";
import { ordersReadWatchers } from "sagas/orders/ordersRead";
import { ordersDeleteWatchers } from "sagas/orders/ordersDelete";
import { barbersReadWatchers } from "sagas/barbers/barbersRead";
import { barbersUpdateWatchers } from "sagas/barbers/barbersUpdate";
import { barbersBlockedDaysWatchers } from "sagas/barbers/barbersBlockedDays";

export default function* root() {
    yield all([
        authWatchers(),
        authInitWatchers(),
        authLogoutWatchers(),
        bookingsReadWatchers(),
        bookingsDeleteWatchers(),
        bookingsUpdateWatchers(),
        bookingsAppointmentsWatchers(),
        bookingsCreateWatchers(),
        productsReadWatchers(),
        productsUpdateWatchers(),
        ordersReadWatchers(),
        ordersDeleteWatchers(),
        barbersReadWatchers(),
        barbersUpdateWatchers(),
        barbersBlockedDaysWatchers(),
    ]);
}
