import React, { useState } from "react";
import { useSelector } from "react-redux";

import { selectBarbers } from "sagas/selectors";

import Heading from "components/atoms/heading/Heading";
import BarberCard from "components/molecules/barberCard/BarberCard";
import BarberSidebar from "components/organisms/barberSidebar/BarberSidebar";

const Barbers = () => {
    const [activeBarberId, setActiveBarberId] = useState(null);
    const { barbers } = useSelector(selectBarbers);

    const onClick = barberId => setActiveBarberId(barberId);

    const activeBarber = barbers?.find(barber => barber.id === activeBarberId);

    return (
        <div className="barbers">
            <Heading type="tertiary">Berberat</Heading>

            <div className="barbers__cards">
                {barbers.map(barber => (
                    <BarberCard key={`barber-${barber.id}`} barber={barber} onEditClick={onClick} />
                ))}
            </div>

            {activeBarber && (
                <BarberSidebar barber={activeBarber} onClose={() => setActiveBarberId(null)} />
            )}
        </div>
    );
};

export default Barbers;
